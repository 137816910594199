@charset "utf-8";

/*======================================
印刷設定用
======================================*/
.print-bg {
  background-color: #f7f7f7;
  padding: 15px;
  height: 100%;

  p {
    font-size: 1.4rem;
    line-height: 150%;
    margin-top: 15px;
    margin-bottom: 5px;
    letter-spacing: -0.03em;
  }
}
.print-frame {
  background-color: #fff;
  padding: 20px;
  margin: 0 auto;
  width: calc(297mm + 40px);
  border: 1px solid #d2d2d2;
}
.print {
  background-color: #fff;
  width: 297mm;
  height: 200mm;
  padding: 10px 10px 10px 30px;

  .title {
    text-align: center;
    font-size: 20px;
    margin-bottom: 15px;
    width: calc(100% - 180px);
  }
  .output_time {
    text-align: right;
    font-size: 16px;
    letter-spacing: -0.03em;
  }
  .map_container {
    width: 100%;
    height: 540px;
    display: table;

    #map_print {
      width: 800px;
      height: 100%;
      display: table-cell;
    }
    .hanrei {
      width: 180px; /* 凡例の横幅設定 */
      display: table-cell;
      vertical-align: bottom;
    }
    .hanrei img {
      width: 100%;
      height: auto;
      padding-left: 15px;
    }
  }
  .note {
    clear: both;
    color: red;
    font-size: 13px;
    line-height: 130%;
    margin-top: 10px;
    margin-left: 3px;
    letter-spacing: -0.03em;
  }
  .footer {
    text-align: right;
    font-size: 16px;
    padding-bottom: 0px;
    letter-spacing: -0.03em;
  }
  .mapboxgl-map {
    border: 1px solid #000;
  }
  ol {
    counter-reset: count 0;
    margin-top: 3px;
    margin-left: 2px;
  }
  li:before {
    content: counter(count) " . ";
    counter-increment: count 1;
    font-size: 13px;
  }
}
.print-button button {
  min-width: 100px;
  font-size: 1.4rem;
  color: #FFF;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #CCC;
  border: 1px solid #CCC;
  transition: filter 0.3s ease;
}
.print-button button:hover {
    filter: brightness(110%);
}
.print-button .y {
  color: #FFF;
  background-color: #5EBD21;
  border: 1px solid #5EBD21;
}
.print-button .n {
  color: #758C9E;
  background-color: #FFF;
  border: 1px solid #758C9E;
}
.header {
  width: calc(297mm + 40px);
  margin: 0 auto;
  padding-bottom: 10px;
}

@page {
  size: A4 landscape;
  margin: 2mm;
}
@media print {
  body {
		background-color: #fff;
  }
  .header {
    display: none;
  }
  .print-bg {
    padding: 0;
    background-color: #fff;
  }
  .print-frame {
    margin: 0;
    padding: 0;
    width: auto;
    border: none;
  }
  .print {
    width: 297mm;
    height: 200mm; // 210mm is correct value but page break was happen
    max-height: 200mm;
    min-height: 200mm;
    padding: 8mm 8mm 0 13mm;
  }
  .map_container {
    margin-left: 5px;
  }
  .output_time {
    padding-top: 5px;
  }
  .footer {
    page-break-after: avoid;
  }
}