@charset "utf-8";
/*======================================
トップ画面のメニュー
======================================*/
.k-menu-bar{
	padding: 15px;
	font-size: 1.8rem;
	font-weight: bold;
	color: #7F7F7F;
	text-align: center;
	background-color: #fff;
	border-bottom: 3px solid #9AC400;
}
.s-top-navi ul.s-top-nav{
	width: 100%;
}
 ul.s-top-nav li{
	font-size: 1.8rem;
	color: #000;
	line-height: 120%;
	border-bottom: solid 1px #D9D9D9;
}
 ul.s-top-nav li a{
	padding: 20px 40px;
	display: block;
	color: #000;
}
ul.s-top-nav li:last-child{
	border-bottom: none;
}

/*======================================
マップ画面のメニュー
======================================*/
.k-top-bar{
	width: 100%;
	height: 20px;
	background-color: #EFF3D9;
}
.s-navi ul.s-nav{
	width: 100%;
/*	margin-top: 15px; */
}
 ul.s-nav li{
	border-bottom: solid 1px #D9D9D9;
	font-size: 1.8rem;
	padding: 20px 40px;
	color: #606060;
	align-items: center;
	display: flex;
}
 ul.s-nav li a {
	align-items: center;
	display: flex;
	color: #606060;
 }

.nav-icon_s{
	width: 40px;
	height: 40px;
	object-fit: contain;
	margin-right: 20px;

	background-position: center;
	background-repeat: no-repeat;
	display: inline-block;
}
.nav-icon_s1{
	background-image: url("/assets/img/kenchiku/nav-icon1.svg");
}
.nav-icon_s2{
	background-image: url("/assets/img/kenchiku/nav-icon2.svg");
}
.nav-icon_s3{
	background-image: url("/assets/img/kenchiku/nav-icon3.svg");
}
.nav-icon_s4{
	background-image: url("/assets/img/kenchiku/nav-icon4.svg");
}
.k-t-header .menu-button span {
	display: block;
	width: 28px;
	position: absolute;
	transition: transform .5s, opacity .5s;
}
.k-t-header .menu-button span:nth-child(-n+3),
.k-m-header .menu-button span:nth-child(-n+3) {
	height: 3px;
	background-color: #fff;
	right: 0;
	left: 0;
	margin: auto;
}
.sp-nav .k-search-box {
	position: absolute;
	width: 100%;
}