@charset "utf-8";


/*==========================================
ヘッダー
===========================================*/

.k-t-header{
	display: flex;
	background: #5EBD21;
	justify-content: space-between;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, .2);
}
.k-m-header{
	display: flex;
	background: #5EBD21;
}
.k-logo{
	background-image: url("/assets/img/kenchiku/logo.svg");
	background-size: contain;
	background-repeat: no-repeat;
	width: 200px;
	height: 24px;
	margin: 10px 15px;
}
.k-logo a {
	display: block;
	width: 100%;
	height: 100%;
}
.k-mappage-logo{
	background-image: url("/assets/img/kenchiku/logo.svg");
	background-size: contain;
	background-repeat: no-repeat;
	width: 200px;
	height: 24px;
	margin: 10px 15px;
}
.k-mappage-logo a {
	display: block;
	width: 100%;
	height: 100%;
}
ul.k-t-nav{
	width: 150px;
	margin-top: 5px; 
	display: flex;
	justify-content: space-between;
}
ul.k-t-nav.k-t-nav-top {
	width: 300px;
	margin-top: 20px; 
}
ul.k-t-nav li{
	font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
	padding: 8px 8px;
	border-radius: 5px;
  cursor: pointer;
	transition: background-color 0.2s ease;
}
ul.k-t-nav.k-t-nav-top li {
	font-size: 1.4rem;
	padding: 8px 15px;
}
ul.k-t-nav li:hover {
	background-color: #f6f6f6;
}
.k-nav-icon {
	width: 22px;
	height: 22px;
	object-fit: contain;
	margin-top: -2px;
	background-position: center;
	background-repeat: no-repeat;
	display: inline-block;
	vertical-align: middle;
}
.k-nav-icon::before {
  content: "";
	width: 22px;
	height: 22px;
	object-fit: contain;
	margin-top: 0;
	margin-right:5px;
	background-position: center;
	background-repeat: no-repeat;
	display: inline-block;
  transition: all 0.1s ease-out;
}
ul.k-t-nav li:hover .k-nav-icon::before {
  opacity: 0;
}
.k-nav-icon1 {
	background-image: url("/assets/img/kenchiku/nav-icon1_on.svg");
}
.k-nav-icon2 {
	background-image: url("/assets/img/kenchiku/nav-icon2_on.svg");
}
.k-nav-icon3 {
	background-image: url("/assets/img/kenchiku/nav-icon3_on.svg");
}
.k-nav-icon4 {
	background-image: url("/assets/img/kenchiku/nav-icon4_on.svg");
}
.k-nav-icon1::before {
	background-image: url("/assets/img/kenchiku/nav-icon1.svg");
}
.k-nav-icon2::before {
	background-image: url("/assets/img/kenchiku/nav-icon2.svg");
}
.k-nav-icon3::before {
	background-image: url("/assets/img/kenchiku/nav-icon3.svg");
}
.k-nav-icon4::before {
	background-image: url("/assets/img/kenchiku/nav-icon4.svg");
}
ul.k-t-nav li a{
	color: #333;
	transition: color 0.2s ease;
}
ul.k-t-nav li:hover a{
	color: #048148;
}
/*==========================================
検索ボックス
===========================================*/
.k-search-box{
	padding: 5px 10px;
	border-bottom: solid 2px #758C9E;
}
.k-search {
  width: 100%;
  position: relative;
  display: flex;
}

.k-searchTerm {
  width: 85%;
  border: none;
  padding: 5px;
  height: 23px;
  border-radius: 5px 0 0 5px;
  border: solid 1px #758C9E;
  outline: none;
  color: #333;
  font-size: 1.2rem;
  border-right: none;
}

.k-searchTerm:focus{
  color: #333;
}
button.k-searchButton {
	background-image: url("/assets/img/search-icon.svg");
	background-size: 20px;
	background-position: center;
	background-repeat: no-repeat;
  transition: filter 0.3s ease;
}
.k-searchButton {
  width: 15%;
  height: auto;
  border: none;
  background: #38A420;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
}
.k-searchButton:hover {
    filter: brightness(110%);
}


/*==========================================
トップの「同意する」ボタン
===========================================*/

.k-y{
	color: #FFF;
	background-color: #38A420;
	border: 1px solid #38A420;
}


/*==========================================
凡例表示部分
===========================================*/
.k-mk-w{
	width:100%;
	position: fixed;
	bottom: 0px;
	z-index: 99;
	-ms-user-select: none; /* IE 10+ */
	-moz-user-select: -moz-none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	user-select: none;
}
.k-mk-w .handle {
	cursor: move;
	pointer-events: none;
}
.k-mk-in{
	width:100%;
	position: relative;
}
.k-close-btn{
	background-image: url("/assets/img/kenchiku/close-btn.svg");
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	width: 40px;
	height: 40px;
	position: absolute;
	top: -20px;
  left: 50%;
  transform: translateX(-50%);
}
img.k-close-btn{
	width: 40px;
	height: auto;
	position: absolute;
	top: -20px;
    left: 50%;
    transform: translateX(-50%);
}
.k-mk-title{
	padding: 6px;
	padding-left: 10px;
	background-color: #5FBE00;
	color: #FFF;
	font-size: 1.4rem;
}
.k-mk-box{
	padding: 5px 10px;
	display: flex;
	flex-wrap: wrap;
	background-color: #FFF;
}
.k-mk-left{
	width: 50%;
}
.k-mk-right{
	width: 50%;
}
.k-mk-text{
	margin: 8px 0 5px;
	font-size: 1.4rem;
	color: #006F4E;
	font-weight: bold;
}
.k-map-key li,
.k-map-key li a {
	display: flex;
	align-items: center;
	padding: 4px 0;
	line-height: 120%;
	height: 20px;
	font-size: min(3vw, 1.3rem);
}
.k-map-key2 li{
	display: flex;
	align-items: center;
	padding: 4px 0;
	line-height: 120%;
	height: 20px;
	font-size: min(3vw, 1.3rem);
}
.k-mk-box a {
	color: #0000ff;
}
.k-mk-box a:hover {
	text-decoration: underline;
}
.k-mk-in li .k-icon1{
	background-image: url("/assets/img/kenchiku/usage1.png");
}
.k-mk-in li .k-icon2{
	background-image: url("/assets/img/kenchiku/usage2.png");
}
.k-mk-in li .k-icon3{
	background-image: url("/assets/img/kenchiku/usage3.png");
}
.k-mk-in li .k-icon4{
	background-image: url("/assets/img/kenchiku/usage4.png");
}
.k-mk-in li .k-icon5{
	background-image: url("/assets/img/kenchiku/usage5.png");
}
.k-mk-in li .k-icon6{
	background-image: url("/assets/img/kenchiku/usage6.png");
}
.k-mk-in li .k-icon7{
	background-image: url("/assets/img/kenchiku/usage7.png");
}
.k-mk-in li .k-icon8{
	background-image: url("/assets/img/kenchiku/usage8.png");
}
.k-mk-in li .k-icon9{
	background-image: url("/assets/img/kenchiku/usage9.png");
}
.k-mk-in li .k-icon10{
	background-image: url("/assets/img/kenchiku/usage10.png");
}
.k-mk-in li .k-icon11{
	background-image: url("/assets/img/kenchiku/usage11.png");
}
.k-mk-in li .k-icon12{
	background-image: url("/assets/img/kenchiku/usage12.png");
}
.k-mk-in li .k-icon13{
	background-image: url("/assets/img/kenchiku/usage13.png");
}
.k-map-k{
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	width: 40px;
	height: 12px;
	margin-right: 0;
}
/*==========================================
地図選択
===========================================*/
.k-close-btn2{
	background-image: url("/assets/img/kenchiku/close-btn.svg");
	background-size: contain;
	background-repeat: no-repeat;
	width: 35px;
	height: 35px;
	position: absolute;
	top: -20px;
	left: 50%;
	transform: translateX(-50%);
}
.k-map-s-title{
	padding: 15px;
	background-color: #5EBD21;
	color: #FFF;
	font-size: 1.8rem;
	margin-bottom: 20px;
}
.k-link-bar{
	padding: 6px 15px;;
	background-color: #5EBD21;
	color: #FFF;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
/*==========================================
 ポップアップ画面
===========================================*/
.kenchiku .mapboxgl-popup-close-button {
	background-image: url("/assets/img/kenchiku/close-btn2.svg");
	background-size: contain;
	background-repeat: no-repeat;
	width: 24px;
	height: 24px;
	position: absolute;
	top: 3px;
	right: 3px;
	text-indent: 200%;
	white-space: nowrap;
	overflow: hidden;
	transition: filter 0.3s ease;
}
.kenchiku .mapboxgl-popup-close-button:hover {
	filter: brightness(120%);
	background-color: transparent;
}
.kenchiku .mapboxgl-popup-content {
		position: relative;
		display: inline-block;
		padding: 0;
		width: 100%;
		min-width: 200px;
		max-width: 300px;
		color: #555;
		/*border: solid 1px #CCC;*/
		box-sizing: border-box;
		-webkit-box-shadow: 2px 2px 3px 1px #CCC;
		box-shadow: 2px 2px 3px 1px #CCC;
		border-radius: 0;
}
.k-pop-title{
	padding: 5px 10px;
	text-align: right;
	color: #FFF;
	font-size: 1.6rem;
	margin-bottom: 2px;
}
ul.k-popup-list{
	width: 100%;
	margin: 0 auto;
	padding: 25px 10px 5px 10px;	
}
ul.k-popup-list li{
	padding: 10px 5px;
	font-size: 1.3rem;
	line-height: 150%;
	border-bottom: solid 1px #C8E290;
}
ul.k-popup-list li:last-child {
	border-bottom: none;
}
ul.k-popup-list li a{
	color: #379A36;
}
ul.k-popup-list li a:hover {
	text-decoration: underline;
}
table.k-popup-table{
	width: 100%;
	border-collapse: collapse;
	margin: 5px 0;
}
table.k-popup-table th,
table.k-popup-table td {
	padding: 5px 10px;
	font-size: 1.4rem;
	line-height: 160%;
	text-align: left;
	border: solid 1px #D9D9D9;
}
table.k-popup-table th {
	width: 35%;
	background-color: #F2F2F2;
}

/*==========================================
 フッター　共通
===========================================*/

.k-f-img{
	height: 50px;	background:url(/assets/img/kenchiku/footer-img.png) repeat-x bottom;
	background-size: auto 50px;
	}
.k-footer-wrap{
	background-color: #9AC400;
	color: #FFF;
	padding: 20px;
}
.k-footer-flex{
	width: 100%;
	max-width: 1000px;
	margin: 0 auto;
}

/*==========================================
TOPページ　メディアクエリ 768以上
===========================================*/
@media only  screen and (min-width:768px) /*768px以上*/{
.k-m-header{
	display: flex;
	background: #FFF;
	border-bottom: solid 2px #758C9E;
}
	.k-mappage-logo-bg{
		background-color: #5EBD21;
	}
	.k-mappage-logo{
		width: 200px;
		height: 24px;
		margin: 12px 13px 0 13px;
	}
	.k-top-nav{
		margin: 2px 20px;
		margin-left:auto;	
	}
	.k-search-box{
		width: 370px;
		padding: 7px 20px;
		border-bottom: none;
	}
	.k-searchTerm {
		width: 85%;
		border: none;
		padding: 5px;
		height: 22px;
		border-radius: 5px 0 0 5px;
		border: solid 1px #758C9E;
		outline: none;
		color: #333;
		font-size: 1.2rem;
		border-right: none;
	}

	.k-searchTerm:focus{
		color: #333;
	}
	.k-searchButton {
		width: 15%;
		height: auto;
		border: none;
		background: #38A420;
		text-align: center;
		color: #fff;
		border-radius: 0 5px 5px 0;
		cursor: pointer;
		font-size: 20px;
	}
	.k-mk-w{
		width: 210px;
		position: absolute;
		right: 30px;
		bottom: 20px;
	}
	.k-close-btn {
		background-image: url("/assets/img/kenchiku/close-btn.svg");
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		width: 30px;
		height: 30px;
		position: absolute;
		top: -15px;
		left: 210px;
	}
	img.k-close-btn{
		width: 30px;
		height: auto;
		position: absolute;
		top: -15px;
		left: 200px;
	}
	.k-mk-w .handle {
		pointer-events: auto;
	}
	.k-mk-title{
		padding: 5px;
		font-size: 1.4rem;
	}
	.k-mk-box{
		padding: 6px 10px;
		border: 1px solid #ECECEC;
		display: flex;
		flex-direction:column;
	}
	.k-mk-left{
		width: 100%;
	
	}
	.k-mk-right{
		width: 100%;		
	}
	.k-mk-text{
		margin: 5px 0;
		font-size: 1.2rem;
	}
	.k-map-key li,
	.k-map-key li a {
		padding: 3px 0;
		font-size: 1.2rem;
	}
	.k-map-key2 li,
	.k-map-key2 li a {
		padding: 3px 0;
		font-size: 1.2rem;
	}
	img.k-map-k{
		width: 40px;
		height: auto;
	}
	.k-f-img{
		height: 60px;
		background-size: auto 60px;
	}
}

@media only screen and (min-width:768px) /*768px以上*/{
	.k-t-header{
		background: #FFF;
	}
	.k-logo-bg{
		width:350px;
		background-color: #5EBD21;
	}
	.k-logo{
		width: 280px;
		height: 33px;
		margin: 20px 35px;
	}
	.k-f-img{
		height: 50px;
		background-size: auto 50px;
	}
	.k-footer-flex{
		display: flex;
	}
	.k-footer-flex-item1{
		width: 70%;
		padding-top: 15px;
	}
	.k-footer-flex-item2 {
			padding-top: 15px;
	}
}
@media only  screen and (min-width:970px) /*970px以上*/{
	.k-footer-flex-item1{
		width: 70%;
		padding-top: 15px;
	}
}
@media only  screen and (min-width:980px) /*980px以上*/{
	ul.k-t-nav{
		width: 350px;
	}
}
