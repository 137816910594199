@charset "utf-8";

/*==========================================
全体
===========================================*/
html {
	font-size: 62.5%;
}
body {
	font-family: "游ゴシック体", "Yu Gothic", YuGothic, 'ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','メイリオ',Meiryo,'ＭＳ Ｐゴシック',sans-serif;
	font-weight: 500;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	background-color: #f7f7f7;
	color: #000;
	letter-spacing: 0.05rem;
}
#wrapper{
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	overflow: hidden;
	z-index:2;
	position: relative;
	background-color: #fff;
}
#wrapper2{
	position: relative;
	background-color: #fff;
}
.sp{
	display:block;
}
.tb{
	display: none;
}
.pc{
	display: none;
}
.ls{
	letter-spacing: normal!important;
}
.red{
	color: red;
}
.center{
	text-align: center !important;
}
.left {
	text-align: left !important;
}
.hide{
	display: none !important;
}
.mt10 {
	margin-top: 10px !important;
}
.mt20 {
	margin-top: 20px !important;
}
.mb10 {
	margin-bottom: 10px !important;
}
.mb20 {
	margin-bottom: 20px !important;
}
.mb30 {
	margin-bottom: 30px !important;
}
.mb40 {
	margin-bottom: 40px !important;
}
.mb60 {
	margin-bottom: 60px !important;
}
.mt30{
	margin-top:30px;
}
.mr10 {
	margin-right: 10px !important;
}
.pt0 {
	padding-top: 0 !important;
}
.err-text{/*　0419追加しました　*/
	padding: 50px 0 50px;
	font-size: 1.6rem;
	line-height: 180%;
	text-align: center;
}
.btnmode {
	-ms-user-select: none; /* IE 10+ */
	-moz-user-select: -moz-none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	user-select: none;
	cursor: pointer;
}
.app-ie {
	display: none;
	width: 80%;
	margin: 0 auto;
}
.app-ie h2 {
	font-size: 2.2rem;
	font-weight: bold;
	line-height: 160%;
	margin-top: 60px;
	margin-bottom: 15px;
}
.app-ie p {
	font-size: 1.6rem;
	line-height: 160%;
}
.app-ie a {
	color: #0000ff;
	font-weight: bold;
}
.app-ie a:hover {
	text-decoration: underline;
}
@media all and (-ms-high-contrast: none) {
	.app-ie {
		display: block;
	}
}
.app-other {
	display: block;
}
@media all and (-ms-high-contrast: none) {
	.app-other {
		display: none;
	}
}
/*==========================================
ヘッダー
===========================================*/
#header{
	position: fixed;
	top: 0;
	right: 0;
    width: 100%;
    z-index: 9998; 
}
.t-header{
	display: flex;
	background: #FFF;
	justify-content: space-between;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, .2);
}
.m-header{
	display: flex;
	background: #FFF;
	border-bottom: solid 2px #048148;
}
.logo{
	background-image: url("/assets/img/shiteidouro/logo.svg");
	background-size: contain;
	background-repeat: no-repeat;
	width: 200px;
	height: 24px;
	margin: 10px 15px;
}
.logo a {
	display: block;
	width: 100%;
	height: 100%;
}
.mappage-logo{
	background-image: url("/assets/img/shiteidouro/logo.svg");
	background-size: contain;
	background-repeat: no-repeat;
	width: 200px;
	height: 24px;
	margin: 10px 15px;
}
.mappage-logo a {
	display: block;
	width: 100%;
	height: 100%;
}
ul.t-nav{
	width: 150px;
	margin-top: 5px; 
	display: flex;
	justify-content: space-between;
}
ul.t-nav.t-nav-top {
	width: 300px;
	margin-top: 20px; 
}
ul.t-nav li{
	font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
	padding: 8px 8px;
	border-radius: 5px;
  cursor: pointer;
	transition: background-color 0.2s ease;
}
ul.t-nav.t-nav-top li {
	font-size: 1.4rem;
	padding: 8px 15px;
}
ul.t-nav li:hover {
	background-color: #f6f6f6;
}
.nav-icon {
	width: 22px;
	height: 22px;
	object-fit: contain;
	margin-top: -2px;
	background-position: center;
	background-repeat: no-repeat;
	display: inline-block;
	vertical-align: middle;
}
.nav-icon::before {
  content: "";
	width: 22px;
	height: 22px;
	object-fit: contain;
	margin-top: 0;
	margin-right:5px;
	background-position: center;
	background-repeat: no-repeat;
	display: inline-block;
  transition: all 0.1s ease-out;
}
ul.t-nav li:hover .nav-icon::before {
  opacity: 0;
}
.nav-icon1 {
	background-image: url("/assets/img/shiteidouro/nav-icon1_on.svg");
}
.nav-icon2 {
	background-image: url("/assets/img/shiteidouro/nav-icon2_on.svg");
}
.nav-icon3 {
	background-image: url("/assets/img/shiteidouro/nav-icon3_on.svg");
}
.nav-icon4 {
	background-image: url("/assets/img/shiteidouro/nav-icon4_on.svg");
}
.nav-icon1::before {
	background-image: url("/assets/img/shiteidouro/nav-icon1.svg");
}
.nav-icon2::before {
	background-image: url("/assets/img/shiteidouro/nav-icon2.svg");
}
.nav-icon3::before {
	background-image: url("/assets/img/shiteidouro/nav-icon3.svg");
}
.nav-icon4::before {
	background-image: url("/assets/img/shiteidouro/nav-icon4.svg");
}
ul.t-nav li a{
	color: #333;
	transition: color 0.2s ease;
}
ul.t-nav li:hover a{
	color: #048148;
}
/*==========================================
検索ボックス
===========================================*/
.search-box{
	background-color: #048148;
	padding: 5px 10px 7px 10px;
}
.search {
  width: 100%;
  position: relative;
  display: flex;
}

.searchTerm {
  width: 85%;
  border: none;
  border-right: none;
  padding: 5px;
  height: 23px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #333;
  font-size: 1.2rem;
}

.searchTerm:focus{
  color: #333;
}
button.searchButton {
	background-image: url("/assets/img/search-icon.svg");
	background-size: 20px;
	background-position: center;
	background-repeat: no-repeat;
  transition: filter 0.3s ease;
}
.searchButton {
  width: 15%;
  height: auto;
  border: none;
  background: #5EBD21;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
}
.searchButton:hover {
    filter: brightness(110%);
}

.results {
	width: 100%;
	height: auto;
	position: absolute;
	top: auto;
	z-index: 9999;
	background-color: #fff;
	left: auto;
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, .3);
	margin-top: 7px;
	margin-left: -10px;
	padding: 10px 0;
}
.results div,
.results p {
	font-size: 1.4rem;
	padding: 10px 15px;
}
.results div:hover {
	background-color: #f0f0f0;
}

/*==========================================
全体
===========================================*/
.container{
	width: 100%;
	max-width: 1000px;
	padding: 30px 10px;
	margin: 30px auto 0;
}
.contents{
	width: 100%;
	padding: 30px 10px;
	margin: 40px auto;
}
.m-container{
	background-color: #fff;
	width: 100%;
	height: 100vh;
	padding-top: 90px;
}
img.map{
	width: 100%;
	height: auto;
	
}
/*==========================================
top画面
===========================================*/
.info-box{
	width: 100%;
	padding: 15px 20px;
	background-color: #EFF3F5;
	box-shadow: 4px 4px 0px 0 #758C9E;	
}
.info-title{
	font-size: 1.6rem;
	font-weight: bold;
	color: #056D5C;
	border-left: thick solid #056D5C;
    padding: 5px 0 3px 6px;
	margin-bottom: 10px;
}
.info-text {
	padding-top: 10px;
}
.info-text p {
	font-size: 1.4rem;
	line-height: 150%;
	margin: 10px 0;
	padding-left: 10px;
	padding-top: 5px;
	margin-top: 10px;
}
.info-text h2 {
	display: inline-block;
	font-size: 1.4rem;
	background-color: #dadada;
	padding: 7px 10px;
	border-radius: 4px;
}
.info-text .bg-info {
	background-color: #BADDFF;
}
.info-text .bg-alert {
	background-color: #FFD1D1;
}
.info-text h3 {
	display: inline-block;
	padding: 10px 0 0 10px;
	font-size: 1.4rem;
}
.info-text ul {
	font-size: 1.4rem;
	line-height: 150%;
	margin: 10px 0;
	margin-left: 25px;
	padding-top: 5px;
	list-style-type: square;
}
.info-text ol {
	font-size: 1.4rem;
	line-height: 150%;
	margin: 10px 0;
	margin-left: 15px;
	padding-top: 5px;
	list-style-type: none;
	counter-reset: count 0;
} 
.info-text ol li::before {
  content: "(" counter(count) ") ";
	counter-increment: count 1;
}
.info-text hr {
	border-top: solid 1px #ddd;
	border-right: none;
	border-bottom: none;
	border-left: none;
	margin: 10px 0 20px 0;
}
.info-text a {
	color: #0066FF;
}
.info-text a:hover {
	text-decoration: underline;
}

/*==========================================
利用条件部分
===========================================*/
.terms{
}
.agr-wrap{
	margin: 50px 0 60px;
}
.agr-wrap p{
	font-size: 1.6rem;
	text-align: center;
	line-height: 130%;
}
.btn-box{
	width: 80%;
	max-width: 410px;
	display: flex;
	justify-content: space-around;
	margin: 30px auto 50px;
}
.agr-btn{
	width: 45%;
	max-width: 180px;
	font-size: 1.8rem;
	color: #FFF;
	padding: 5px;
	border-radius: 5px;
	background-color: #CCC;
	border: 1px solid #CCC;
  transition: filter 0.3s ease;
}
.agr-btn:hover {
    filter: brightness(110%);
}
.y{
	color: #FFF;
	background-color: #5EBD21;
	border: 1px solid #5EBD21;
}
.n{
	color: #758C9E;	
	background-color: #FFF;
	border: 1px solid #758C9E;
}
.n a{
	display: block;
	color: #758C9E;	
}

.terms h2{
	width: 100%;
	font-size: 1.8rem;
	font-weight: bold;
	color: #048148;
	text-align: center;
	border-bottom: 1px solid #048148;
	padding-bottom: 5px;
	margin: 30px 0;
	
}
.terms ol{
	padding-right: 20px;
	list-style: none;
	counter-reset:num;
}
.terms ol li {
	counter-increment:num ;
}
.terms ol li::before {
	content:counter(num) ". ";
}
.terms ol li {
	padding-left: 26px;
	text-indent: -1.0em;
	font-size: 1.4rem;
	line-height: 160%;
	padding-bottom: 10px;
 }
.description {
	text-align: left !important;
	width: 90%;
	margin: 0 auto;
	margin-bottom: 40px;
}

/*==========================================
凡例表示部分
===========================================*/
.mk-w{
	width:100%;
	position: fixed;
	bottom: 0px;
	z-index: 99;
	-ms-user-select: none; /* IE 10+ */
	-moz-user-select: -moz-none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	user-select: none;
}
.mk-w .handle {
	cursor: move;
	pointer-events: none;
}
.mk-in{
	width:100%;
	position: relative;
}
.close-btn{
	background-image: url("/assets/img/shiteidouro/close-btn.svg");
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	width: 40px;
	height: 40px;
	position: absolute;
	top: -20px;
  left: 50%;
  transform: translateX(-50%);
}
img.close-btn{
	width: 40px;
	height: auto;
	position: absolute;
	top: -20px;
    left: 50%;
    transform: translateX(-50%);
}
.mk-title{
	padding: 6px;
	padding-left: 10px;
	background-color: #006F4E;
	color: #FFF;
	font-size: 1.4rem;
}
.mk-box{
	padding: 5px 5px 5px 20px;
	display: flex;
	flex-wrap: wrap;
	background-color: #FFF;
}
.mk-left{
	width: 35%;	
}
.mk-right{
	width: 65%;		
}
.mk-text{
	margin: 8px 0 5px;
	font-size: 1.4rem;
	color: #006F4E;
	font-weight: bold;
}
.map-key li{
	display: flex;
	align-items: center;
	padding: 4px 0;	
	font-size: min(3vw, 1.3rem);
}
.map-key2 li{
	display: flex;
	align-items: center;
	padding: 5px 0;
	line-height: 120%;
	font-size: min(3vw, 1.3rem);
}
.map-key2 li:nth-child(3){
	align-items:flex-start;
}
.s-txt{
	letter-spacing: -0.1em;
	font-size: min(2.4vw, 1.1rem);
}
.mk-in li .icon1{
	background-image: url("/assets/img/shiteidouro/usage1.png");
}
.mk-in li .icon2{
	background-image: url("/assets/img/shiteidouro/usage2.png");
}
.mk-in li .icon3{
	background-image: url("/assets/img/shiteidouro/usage3.png");
}
.mk-in li .icon4{
	background-image: url("/assets/img/shiteidouro/usage4.png");
}
.mk-in li .icon5{
	background-image: url("/assets/img/shiteidouro/usage5.png");
}
.mk-in li .icon6{
	background-image: url("/assets/img/shiteidouro/usage6.png");
}
.mk-in li .icon7{
	background-image: url("/assets/img/shiteidouro/usage7.png");
}
.mk-in li .icon8{
	background-image: url("/assets/img/shiteidouro/usage8.png");
	width: 40px;
}
.map-k{
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	width: 40px;
	height: 12px;
	margin-right: 5px;
}
/*==========================================
地図選択
===========================================*/
.map-s-box{
	width:90%;
	position: relative;
	background-color: #ffffff;
}
.close-btn2{
	background-image: url("/assets/img/shiteidouro/close-btn.svg");
	background-size: contain;
	background-repeat: no-repeat;
	width: 35px;
	height: 35px;
	position: absolute;
	top: -20px;
	left: 50%;
	transform: translateX(-50%);
}
.map-s-title{
	padding: 15px;
	background-color: #006F4E;
	color: #FFF;
	font-size: 1.8rem;
	margin-bottom: 20px;
}
.link-bar{
	padding: 6px 15px;;
	background-color: #006F4E;
	color: #FFF;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.mps-title{
	font-size: 1.6rem;
}
.arrow{
	background-image: url("/assets/img/arrow.svg");
	background-size: contain;
	background-repeat: no-repeat;
	width: 12px;
	height: 12px;
}
.link-box{
	width: 96%;
	display: flex;
	margin: 10px auto;
}
.thumb{
	width: 50%;
	height: auto;
	margin-right: 20px;
}
.thumb-img {
	width: 100%;
  height: 0;
  padding-top: calc(102 / 160 * 100%); /* calc(画像高さ ÷ 画像横幅 × 100%) */
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}
.thumb-img1 {
	background-image: url("/assets/img/shiteidouro.png");
	background-size: 100% auto;
	width: calc(100% - 20px);
}
.thumb-img2 {
	background-image: url("/assets/img/kenchiku.png");
	background-size: 100% auto;
	width: calc(100% - 20px);
}
.thumb-img3 {
	background-image: url("/assets/img/nintei.png");
}
.thumb-img4 {
	background-image: url("/assets/img/toshi.png");
}
.exp{
	font-size: 1.4rem;
	line-height: 150%;
}
.link-txt{
	font-size: 1.8rem;
	color: #006F4E;
	font-weight: bold;
	margin: 25px 0 10px 15px; 
}

/*==========================================
 ポップアップ画面
===========================================*/
.mapboxgl-popup-content {
    position: relative;
    display: inline-block;
    padding: 0;
    width: 100%;
	  min-width: 240px;
    max-width: 240px;
    color: #555;
    /*border: solid 1px #CCC;*/
    box-sizing: border-box;
    -webkit-box-shadow: 2px 2px 3px 1px #CCC;
    box-shadow: 2px 2px 3px 1px #CCC;
		border-radius: 0;
}

.pop-up-box:before {
	content: "";
	position: absolute;
	bottom: -24px;
	left: 50%;
	margin-left: -15px;
	border: 12px solid transparent;
	border-top: 13px solid #FFF;
	z-index: 2;

}
.pop-up-box:after {
  content: "";
  position: absolute;
  bottom: -30px;
  left: 50%;
  margin-left: -17px;
  border: 15px solid transparent;
  border-top: 15px solid #CCC;
  z-index: 1;
}
.mapboxgl-popup-close-button {
	background-image: url("/assets/img/shiteidouro/close-btn2.svg");
	background-size: contain;
	background-repeat: no-repeat;
	width: 24px;
	height: 24px;
	position: absolute;
	top: 3px;
	right: 3px;
	text-indent: 200%;
	white-space: nowrap;
	overflow: hidden;
	transition: filter 0.3s ease;
}
.mapboxgl-popup-close-button:hover {
	filter: brightness(120%);
	background-color: transparent;
}
.pop-title{
	padding: 5px 10px;
	background-color: #006F4E;
	color: #FFF;
	font-size: 1.4rem;
	margin-bottom: 2px;
}
.detail{
	width: 100%;
	margin: 0 auto;
	padding: 5px 10px;
}
.detail dl{
  display: flex;
  flex-wrap: wrap;
  width: 100%;	
}
.detail dt,
.detail dd{
	font-size: 1.3rem;
	padding-bottom: 4px;
	margin-bottom: 4px;
	border-bottom: solid 1px #006F4E;
}
.detail dt:nth-last-child(2),
.detail dd:last-child{
	border-bottom: none;
}
.detail dt{
	width: 37%;
	text-align: right;
	padding-right: 10px;
}
.detail dd{
	width: 63%;
}
.dtl-btn-box{
	text-align: center;
	margin-bottom: 10px;
}
.dtl-btn{
	width: 45%;
	padding: 5px ;
	font-size: 1.4rem;
	color: #FFF;
	background-color: #006F4E;
	border-radius: 5px;
}
/*==========================================
 動作環境と質問
===========================================*/
.container2{
	width: 100%;
	padding: 20px 0;
	margin: 60px 0;
}
.container2 h1{
	font-size: 1.8rem;
	font-weight: bold;
	color: #048148;
	text-align: center;
	margin-bottom: 15px;
}
.gry-wrap{
	width: 100%;
	padding: 20px 0;
	background-color: #F2F2F2;
}
.white-wrap{
	width: 95%;
	max-width: 1000px;
	padding: 30px 15px;
	margin: 20px auto;
	background-color: #FFF;
	border-radius: 5px;
}
.white-wrap h2{
	font-size: 1.4rem;
	font-weight: bold;
	color: #048148;
	margin-bottom: 8px;	
}
.white-wrap p{
	font-size: 1.2rem;
	line-height: 140%;
	margin-bottom: 20px;
}
.faq-wrap{
	width: 95%;
	max-width: 1000px;
	padding: 0 15px;
	margin: 20px auto;
}
.faq-tab {
	position: relative;
	margin-bottom: 20px;
	width: 100%;
	font-size: 1.4rem;
	background-color: #FFF;
}
.toggle {
	display: none;
}
.faq-title,
.faq-content {
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	transform: translateZ(0);
	transition: all 0.3s;
}
.faq-title {
	padding: 14px 18px;
	display: block;
}
.faq-title::after,
.faq-title::before {
	content: "";
	position: absolute;
	right: 20px;
	top: 18px;
	width: 2px;
	height: 15px;
	background-color: #38A420;
	transition: all 0.3s;
}
.faq-title::after {
	transform: rotate(90deg);
}
.faq-title-q {
	font-size: 1.4rem;
	margin-top:0;
	margin-left: 35px;
	margin-right: 18px;
	line-height: 150%;
}
.faq-title-q::before {
	content: "";
	background: url(/assets/img/faq-q.svg) no-repeat;
	display: inline-block;
	width: 35px;
	height: 35px;
	background-size: contain;
	vertical-align: middle;
	position: absolute;
	top: 8px;
	left: 10px;
}
.faq-content {
	max-height: 0;
	overflow: hidden;
}
.faq-content-a {
	font-size: 1.4rem;
	line-height: 150%;
	margin-top: 10px;
	margin-left: 35px;
	padding: 10px 30px 20px 20px;
}
.faq-content-a::before {
	content: "";
	background: url(/assets/img/faq-a.svg) no-repeat;
	display: inline-block;
	width: 35px;
	height: 35px;
	background-size: contain;
	vertical-align: middle;
	position: absolute;
	top: 18px;
	left: 10px;
}
.toggle:checked + .faq-title + .faq-content {
	max-height: 500px;
	transition: all 1.5s;
}
.toggle:checked + .faq-title::before {
	transform: rotate(90deg) !important;
}

/*==========================================
 操作説明
===========================================*/
.manual img {
	max-width: 100%;
}
.manual h2:nth-child(n + 2) {
	margin-top: 60px;
}
.manual h2 {
	color: #000;
	font-size: 1.6rem;
}
.manual b {
	font-weight: bold;
}
.manual ol {
	font-size: 1.2rem;
	line-height: 140%;
	margin-left: 15px;
	margin-top: 10px;
}
@media only  screen and (min-width:768px) /*768px以上*/{
	.manual p {
		margin-bottom: 20px !important;
	}
	.manual ol {
		font-size: 1.4rem;
		line-height: 150%;
		margin-left: 18px;
	}
	.manual h2 {
		font-size: 1.8rem !important;
	}
}

/*==========================================
 フッター　共通
===========================================*/

footer{
	margin-top: auto;
}
footer a:hover {
	text-decoration: underline;
}
.f-img{
	height: 50px;
	background:url(/assets/img/shiteidouro/footer-img.png) repeat-x bottom;
	background-size: auto 50px;
	}
.footer-wrap{
	background-color: #048148;
	color: #FFF;
	padding: 20px;
}
.footer-flex{
	width: 100%;
	max-width: 1000px;
	margin: 0 auto;
}
footer p.ma{
	font-size: 1.7rem;
	line-height: 140%;
}
footer p.add{
	font-size: 1.2rem;
	line-height: 140%;
	margin: 15px 0;
}
footer p.copy{
	font-size: 1.3rem;
	line-height: 140%;
	margin: 15px 0 0;
}
.footer-nav{
	padding: 0 0 30px;
}
ul.f-nav{
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}
ul.f-nav li{
	padding: 3px 10px;
	font-size: 1.4rem;
}
ul.f-nav li:first-child{
	padding-left: 0;	
}
ul.f-nav li+ li {
    border-left: 1px solid #CCC;
    }
ul.f-nav li a{
	color:#FFF;
}
.link-wrap p{
	font-size: 1.6rem;
	font-weight: bold;
}
ul.link{
	margin-top: 8px;
}
ul.link li{
	padding: 4px 0;
	font-size: 1.4rem;
}
ul.link li a{
	color: #FFF;
}

/*==========================================
	ポップアップ関係
===========================================*/
.popup-usage {
	display: block;
  animation: popup-usage-anim 0.6s cubic-bezier(0.22, 1, 0.36, 1) 1;/* forwards;*/
}
 
@keyframes popup-usage-anim {
  0% {
    transform: translateY(40px) scale(0.8);
    opacity: 0;
  }
  100% {
    transform: translateY(0) scale(1.0);
  }
  80%, 100% {
    opacity: 1;
  }
}

.search-bg {
	top: 93px;
	left: 0;
	background-color: rgba(0, 0, 0, 0);
	width : 100%;
	height: 100%;
	position: fixed;
}

.popup-bg {
	top: 0;
	left: 0;
	z-index: 9998;
	background-color: rgba(0, 0, 0, 0.8);
	width : 100%;
	height: 100%;
	position: fixed;
/*	opacity: 0;*/
}
.popup-frame {
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
	height: auto;
	position: fixed;
	z-index: 9999;
}
.popup-frame-inner {
	overflow-y: auto;
	max-height: 80vh;
}
.popup-frame-inner::-webkit-scrollbar {
  width: 10px;
}
.popup-frame-inner::-webkit-scrollbar-track {
	background: #f0f0f0;
}
.popup-frame-inner::-webkit-scrollbar-thumb {
  background: #cdcdcd;
  border-radius:5px;
}
.popup-frame-inner {
    scrollbar-width: thin;
    scrollbar-color: #cdcdcd#f0f0f0;
}
.popup-win-open {
  animation: popup-win-open-anim 0.8s cubic-bezier(0.25, 1, 0.5, 1) 1 forwards;
	animation-fill-mode: forwards;
}

.popup-win-close {
  animation: popup-win-close-anim 0.5s cubic-bezier(0.25, 1, 0.5, 1) 1 forwards;
	animation-fill-mode: forwards;
}

@keyframes popup-win-open-anim {
  0% {
    opacity: 0;
    transform: scale(0.8) translateY(-60%) translateX(-60%);
		-webkit-transform: scale(0.8) translateY(-60%) translateX(-60%);
		visibility: visible;
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(-50%) translateX(-50%);
		-webkit-transform: scale(1) translateY(-50%) translateX(-50%);
  }
}

@keyframes popup-win-close-anim {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
		visibility: hidden;
  }
}

.popup-bg.fadein {
	animation: fadeIn 0.7s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
	animation-fill-mode: forwards;
}
@keyframes fadeIn {
	0% {
		visibility: visible;
		display: block;
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.popup-bg.fadeout {
	animation: fadeOut 0.7s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
}
@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		visibility: hidden;
	}
}

/*==========================================
TOPページ　メディアクエリ 768以上
===========================================*/
@media only  screen and (min-width:768px) /*768px以上*/{
	#wrapper2{
		min-height: 100vh;
		max-height: 100vh;
		position: relative;
		overflow: hidden;
	}
	.sp{
		display: none !important;
	}
	.pc{
		display: block;
	}
	.tb{
		display: block;
	}
	.mappage-logo{
		width: 200px;
		height: 24px;
		margin: 12px 13px 0 13px;
	}
	.top-nav{
		margin: 2px 20px;
		margin-left:auto;	
	}
	.info-box{
		padding: 30px;
		margin: 30px 0;
	}
	.search-box{
		width: 370px;
		padding: 8px 20px 7px 20px;
	}
	.searchTerm {
		width: 85%;
		border: none;
		border-right: none;
		padding: 5px;
		height: 22px;
		border-radius: 5px 0 0 5px;
		outline: none;
		color: #333;
		font-size: 1.2rem;
	}

	.searchTerm:focus{
		color: #333;
	}
	.searchButton {
		width: 15%;
		height: auto;
		border: none;
		background: #5EBD21;
		text-align: center;
		color: #fff;
		border-radius: 0 5px 5px 0;
		cursor: pointer;
		font-size: 20px;
	}

	.agr-btn{
		padding: 8px;
	}
	.mk-w{
		width: 210px;
		position: absolute;
		right: 30px;
		bottom: 20px;
	}
	.close-btn {
		background-image: url("/assets/img/shiteidouro/close-btn.svg");
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		width: 30px;
		height: 30px;
		position: absolute;
		top: -15px;
		left: 210px;
	}
	img.close-btn{
		width: 30px;
		height: auto;
		position: absolute;
		top: -15px;
		left: 200px;
	}
	.m-container{
		padding-top: 46px;
		height: 100vh;
	}
	.mk-w .handle {
		pointer-events: auto;
	}
	.mk-title{
		padding: 5px;
		font-size: 1.4rem;
	}
	.mk-box{
		padding: 6px 5px 6px 10px;
		border: 1px solid #ECECEC;
		display: flex;
		flex-direction:column;
	}
	.mk-left{
		width: 100%;
		margin-bottom: 10px;
	}
	.mk-right{
		width: 100%;		
	}
	.mk-text{
		margin: 5px 0;
		font-size: 1.2rem;
	}
	.map-key li{
		padding: 3px 0;
		font-size: 1.2rem;
	}
	.map-key2 li{
		padding: 3px 0;
		font-size: 1.2rem;
		line-height: 120%;
	}
	.s-txt{
		letter-spacing: -0.1em;
		font-size: 1rem;
	}
	img.map-k{
		width: 40px;
		height: auto;
	}
	.map-s-box{
		width: 500px;
	}
	.container2 h1{
		margin-top: 40px;
		font-size: 2.0rem;
	}
	.white-wrap{
		padding: 40px;
		margin: 30px auto;
	}
	.white-wrap h2{
		font-size: 1.6rem;
		margin-bottom: 10px;
	}
	.white-wrap p{
		font-size: 1.4rem;
		line-height: 150%;
		margin-bottom: 30px;
	}
	.faq-wrap{
		margin: 30px auto;
		}
	.faq-title {
		padding: 25px;
		display: block;
	}
	.faq-title::after,
	.faq-title::before {
		right: 35px;
		top: 25px;
		width: 2px;
		height: 19px;
	}
	.faq-title-q {
		margin-left: 60px;
		margin-right: 25px;
	}
	.faq-title-q::before {
		width: 38px;
		height: 38px;
		top: 17px;
		left: 22px;
	}
	.faq-content-a {
		margin-top: 10px;
		margin-left: 60px;
		padding: 10px 60px 30px 30px;
	}
	.faq-content-a::before {
		width: 38px;
		height: 38px;
		top: 18px;
		left: 22px;
	}
	.f-img{
		height: 60px;
		background:url(/assets/img/shiteidouro/footer-img.png) repeat-x bottom;
		background-size: auto 60px;
		}
	.search-bg {
		top: 48px;
	}
	.results {
		width: 370px;
		margin-top: 9px;
		margin-left: -20px;
	}

	.info-text h3 {
		padding-top: 0;
	}
}

@media only screen and (min-width:768px) /*768px以上*/{
	.description {
		width: 840px;
	}
	.container{
		padding: 60px 10px 30px 10px;
	}
	.logo{
		width: 280px;
		height: 33px;
		margin: 20px 35px;
	}
	.sp2{
		display: none !important;
	}
	.white-wrap{
		padding: 60px;
		margin: 50px auto;
	}
	.f-img{
		height: 50px;
		background:url(/assets/img/shiteidouro/footer-img.png) repeat-x bottom;
		background-size: auto 50px;
	}
		.footer-flex{
		display: flex;
	}
	.footer-flex-item1{
		width: 70%;
		padding-top: 15px;
	}
	.footer-flex-item2{
		padding-top: 15px;
	}
}
@media only  screen and (min-width:980px) /*980px以上*/{
	ul.t-nav{
		width: 350px;
	}
}

/* 印刷時設定 */
/*
@media print {
	header {
		display: none;
	}
*/
/*
	header .search-box {
		display: none;
	}
	header .top-nav .pc {
		display: none;
	}
	header .hb_menu {
		display: none;
	}
*/
/*
	#usageBox {
		display: none;
	}
}
*/