@charset "utf-8";

/*======================================
スマホ表示判定用
======================================*/
.sp-check {
	width: 100%;
	height : 0;
}

/*======================================
トップ画面のメニュー
======================================*/
.menu-bar{
	padding: 15px;
	font-size: 1.8rem;
	font-weight: bold;
	color: #FFF;
	text-align: center;
	background-color: #048148;
}
.s-top-navi ul.s-top-nav{
	width: 100%;
}
 ul.s-top-nav li{
	font-size: 1.8rem;
	color: #000;
	line-height: 120%;
	border-bottom: solid 1px #D9D9D9;
}
 ul.s-top-nav li a{
	padding: 20px 40px;
	display: block;
	color: #000;
}
ul.s-top-nav li:last-child{
	border-bottom: none;
}

/*======================================
マップ画面のメニュー
======================================*/
.top-bar{
	width: 100%;
	height: 20px;
	background-color: #048148;
}
.s-navi ul.s-nav{
	width: 100%;
/*	margin-top: 15px; */
}
 ul.s-nav li{
	border-bottom: solid 1px #D9D9D9;
	font-size: 1.8rem;
	padding: 20px 40px;
	color: #606060;
	align-items: center;
	display: flex;
}
 ul.s-nav li a {
	align-items: center;
	display: flex;
	color: #606060;
 }

.nav-icon_s{
	width: 40px;
	height: 40px;
	object-fit: contain;
	margin-right: 20px;

	background-position: center;
	background-repeat: no-repeat;
	display: inline-block;
}
.nav-icon_s1{
	background-image: url("/assets/img/shiteidouro/nav-icon1.svg");
}
.nav-icon_s2{
	background-image: url("/assets/img/shiteidouro/nav-icon2.svg");
}
.nav-icon_s3{
	background-image: url("/assets/img/shiteidouro/nav-icon3.svg");
}
.nav-icon_s4{
	background-image: url("/assets/img/shiteidouro/nav-icon4.svg");
}

.nav-icon-txt {
	display: none;
}

.menu-button {
	display: block;
	position: relative;
	width: 36px;
	height: 30px;
}
.hb_menu {
	display: block;
	padding-top: 8px;
	padding-right: 8px;
	margin-left: auto;
}
.menu-button span {
	display: block;
	width: 28px;
	position: absolute;
	transition: transform .5s, opacity .5s;
}
.menu-button span:nth-child(-n+3) {
	height: 3px;
	background-color: #333;
	right: 0;
	left: 0;
	margin: auto;
}
.menu-button span:nth-child(1) {
	top: 4px;
}
.menu-button span:nth-child(2) {
	top: 12px;
}
.menu-button span:nth-child(3) {
	top: 20px;
}
.menu-button.show span:nth-child(1) {
	transform: translateY(8px) rotate(-45deg);
}
.menu-button.show span:nth-child(2) {
	opacity: 0;
}
.menu-button.show span:nth-child(3) {
	transform: translateY(-8px) rotate(45deg);
}
.sp-nav .search-box {
	position: absolute;
	width: 100%;
}
.menu-open{
  animation: slidein 0.5s forwards;
}

.sp-menu {
	z-index: 9999;
	position: absolute;
	background-color: #fff;
	width: 100%;
	height: calc(100vh - 49px);
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}

@keyframes slidein {
	0% {
		opacity: 0;
		transform: translateX(70vw);
		visibility: hidden;
	}
	100% {
		opacity: 1;
		transform: translateX(0);
		visibility: visible;
	}
}
.menu-close{
  animation: slideout 0.5s forwards;
}
@keyframes slideout {
	0% {
		opacity: 1;
		transform: translateX(0);
		visibility: visible;
	}
	100% {
		opacity: 0;
		transform: translateX(70vw);
		visibility: hidden;
	}
}

@media only  screen and (min-width:980px) /*960px以上*/{
	.nav-icon-txt {
		display: inline-block;
		margin-left: 5px;
	}
}

@media only  screen and (min-width:768px) /*768px以上*/{
	.sp-menu {
		display: none;
	}
	.hb_menu {
		display: none;
	}
	.sp-check {
		width: 0;
	}
}