//@import '~bootstrap/scss/bootstrap';
@import 'npm:maplibre-gl/dist/maplibre-gl.css';
@import 'print';
@import './assets/css/reset.css';
/* 指定道路図用 */
@import './assets/css/style.css';
@import './assets/css/navi.css';
/* 建築情報マップ用 */
@import './assets/css/style2.css';
@import './assets/css/navi2.css';
/* 印刷設定用 */
@import './assets/css/print.scss';

html, body {
  width: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

.map_container {
  width: 100%;
  height: 100%;
}

#map {
  width: 100%;
  height: 100%;
}

#layer {
/*  position: absolute; */
  top: 20px;
  right: 20px;
  background-color: white;
}
