@page {
  size: A4;
  margin: 15mm 5mm 15mm;
}

@media print {
  #map {
    height: 140mm !important;
  }
  #layer {
    display: none;
  }
}